<template>
  <div class="form-group">
    <div v-if="withLabel" class="form-group__label-wrapper">
      <label class="form-group__label">{{ label }}</label>
    </div>
    <div class="input-group d-flex justify-content-between align-items-center">
      <div class="input-group-prepend">
        <button class="btn btn-secondary" type="button" :disabled="reactiveValue === minValue" @click="--reactiveValue">
          <span class="fa fa-minus"></span>
        </button>
      </div>
      <div class="font-size-h4">{{ reactiveValue }}</div>
      <div class="input-group-append">
        <button class="btn btn-secondary" type="button" :disabled="reactiveValue === maxValue" @click="++reactiveValue">
          <span class="fa fa-plus"></span>
        </button>
      </div>
    </div>
    <span v-if="helpMessage" class="form-text text-muted">{{ helpMessage }}</span>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: Number,
      default: 0
    },
    minValue: {
      type: Number,
      default: 0
    },
    maxValue: {
      type: Number,
      default: 5
    },
    withLabel: {
      type: Boolean,
      default: true
    },
    helpMessage: {
      type: String,
      default: ''
    },
  },
  data: () => ({
    reactiveValue: 0
  }),
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.reactiveValue = val;
      },
    },
    reactiveValue(val) {
      this.$emit('change', val);
    }
  }
}
</script>