<template>
  <div class="card card-custom w-100">
    <div class="card-header">
      <div class="card-title">
        {{ $t('title.booking_form') }}
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-4">
          <MultiselectFormGroup
              class="app-view__form-group mb-7"
              :label="$t('label.apartment')"
              :placeholder="$t('placeholder.choose_apartment')"
              :value="computedApartmentsList.find(el => el.id === form.apartment_id)"
              :options="computedApartmentsList"
              required
              :with-error="error.apartment_id"
              @select="form.apartment_id = $event.id"
              @remove="form.apartment_id = null"
          />
          <DatetimePickerFormGroup
              :label="$t('label.income_date')"
              :placeholder="$t('placeholder.choose_income_date')"
              :value="form.income_date"
              :min-datetime="computedMinIncomeDate"
              :max-datetime="computedMaxIncomeDate"
              :disabled-dates="disabledDates"
              allow-clear
              required
              :with-error="error.income_date"
              :disabled="!form.apartment_id"
              :help-message="$t('help.choose_apartment_first')"
              @change="form.income_date = $event"
          />
          <DatetimePickerFormGroup
              :label="$t('label.exit_date')"
              :placeholder="$t('placeholder.choose_exit_date')"
              :value="form.exit_date"
              :min-datetime="computedMinExitDate"
              :max-datetime="computedMaxExitDate"
              :disabled-dates="exitDisabledDates"
              allow-clear
              required
              :with-error="error.exit_date"
              :disabled="!form.apartment_id || !form.income_date"
              :help-message="$t('help.choose_apartment_and_income_date_first')"
              @change="form.exit_date = $event"
          />
        </div>
        <div class="col-md-4">
          <TouchspinFormGroup
              :label="$t('label.adults')"
              :value="form.guests.adults"
              :min-value="1"
              @change="form.guests.adults = $event"
          />
          <TouchspinFormGroup
              :label="$t('label.children')"
              :value="form.guests.children"
              :min-value="0"
              @change="form.guests.children = $event"
          />
          <TagInputFormGroup
              class="app-view__input-group"
              :label="$t('label.children_age')"
              :placeholder="$t('placeholder.enter_children_age')"
              :value="childrenAgeTags"
              :disabled="form.guests.children < 1"
              :required="form.guests.children > 0"
              :with-error="error.children_age"
              :error-message="childrenTagInputErrorMessage"
              :beforeAddingTag="checkTag"
              @input="onTagsInput"
          />
        </div>
        <div class="col-md-4">
          <TextareaFormGroup
              :label="$t('label.comment')"
              :placeholder="$t('placeholder.enter_comment')"
              :value="form.comment"
              @input="form.comment = $event"
          />
        </div>
      </div>
    </div>
    <div class="card-footer d-flex justify-content-end">
      <button type="button" class="btn btn-outline-danger font-weight-bold mr-3" @click="clearForm">
        {{ $t('btn.clear') }}
      </button>
      <button type="button"
              class="btn btn-success font-weight-bold"
              @click="showModal"
      >
        {{ $t('btn.book') }}
      </button>
    </div>

    <b-modal ref="confirmBooking"
             :title="$t('title.booking_confirmation')"
             body-class="p-0"
             centered hide-header hide-footer
    >
      <CardLoader v-if="isLoading" :with-text="false"/>
      <div class="p-7">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div class="text-center">{{ $t('help.who_will_get_keys') }}</div>
          <div class="">
            <b-button v-for="option in bookingOwnerOptions"
                      :key="option.id"
                      variant="light-primary"
                      class="mt-3"
                      :class="option.id === 'owner' ? 'mr-3' : ''"
                      :pressed="option.id === form.who"
                      @click="form.who = option.id"
            >
              {{ option.label }}
            </b-button>
          </div>
        </div>

        <div v-if="computedBookForGuest">
          <RadioFormGroup
              class="mt-5"
              :label="$t('label.generate_key')"
              :options="computedGenerateKeyOptions"
              inline
              :with-error="modalError.type"
              @change="form.guests.type = $event"
          />
          <div v-if="form.guests.type" class="alert alert-custom alert-light-primary fade show">
            {{ $t('help.ekey_desc_'+form.guests.type) }}
          </div>
          <InputFormGroup
              :label="$t('label.name')"
              :placeholder="$t('placeholder.enter_guest_name')"
              :required="computedBookForGuest"
              :with-error="modalError.name"
              @keydown.native="ignoreNonEnglishLetters"
              @input="form.guests.name = $event"
          />
          <InputFormGroup
              :label="$t('label.last_name')"
              :placeholder="$t('placeholder.enter_guest_last_name')"
              :required="computedBookForGuest"
              :with-error="modalError.last_name"
              @keydown.native="ignoreNonEnglishLetters"
              @input="form.guests.last_name = $event"
          />
          <RadioFormGroup
            :label="$t('label.gender')"
            :options="computedGendersList"
            :required="computedBookForGuest"
            inline
            :with-error="modalError.gender"
            @change="form.guests.gender = $event"
          />
          <InputFormGroup
              :label="$t('label.email')"
              :placeholder="$t('placeholder.enter_guest_email')"
              :required="computedBookForGuest"
              :with-error="modalError.email"
              @keydown.native="ignoreNonEnglishLetters"
              @input="form.guests.email = $event"
          />
          <PhoneNumberFormGroup
              :label="$t('label.phone_number')"
              :placeholder="$t('placeholder.enter_guest_phone_number')"
              :required="computedBookForGuest"
              :with-error="modalError.phone_number"
              @input="form.guests.phone_number = $event"
          />
<!--          <CountrySelectFormGroup-->
<!--              :label="$t('label.country')"-->
<!--              :placeholder="$t('placeholder.choose_guest_country')"-->
<!--              :required="computedBookForGuest"-->
<!--              :with-error="modalError.country"-->
<!--              @select="form.guests.country = $event"-->
<!--          />-->
          <CountrySelectFormGroup
              :label="$t('label.citizenship')"
              :placeholder="$t('placeholder.choose_guest_citizenship')"
              :required="computedBookForGuest"
              :with-error="modalError.citizen"
              @select="form.guests.citizen = $event"
          />
          <InputFormGroup
              :label="$t('label.personal_number')"
              :placeholder="$t('placeholder.enter_guest_personal_id')"
              :required="computedBookForGuest"
              :with-error="modalError.personal_id"
              @keydown.native="ignoreNonEnglishLetters"
              @input="form.guests.personal_id = $event"
          />
<!--          <InputFormGroup-->
<!--              :label="$t('label.address')"-->
<!--              :placeholder="$t('placeholder.enter_guest_address')"-->
<!--              :required="computedBookForGuest"-->
<!--              :with-error="modalError.address"-->
<!--              @keydown.native="ignoreNonEnglishLetters"-->
<!--              @input="form.guests.address = $event"-->
<!--          />-->
        </div>
        <div v-if="computedBookForMyself" class="mt-5">
          <div class="">
            <span>{{ $t('label.name') }}: </span>
            <span class="font-weight-bolder">{{ `${user.name} ${user.last_name}` }}</span>
          </div>
          <div class="">
            <span>{{ $t('label.phone_number') }}: </span>
            <span class="font-weight-bolder">{{ user.phone_number }}</span>
          </div>
          <div class="">
            <span>{{ $t('label.email') }}: </span>
            <span class="font-weight-bolder">{{ user.email }}</span>
          </div>
          <div class="">
            <span>{{ $t('label.personal_id') }}: </span>
            <span class="font-weight-bolder">{{ user.personalId }}</span>
          </div>
        </div>

        <div v-if="computedBookForMyself || computedBookForGuest" class="d-flex justify-content-center mt-10">
          <button type="button"
                  class="btn btn-hover-bg-danger btn-text-danger btn-hover-text-white border-0 font-weight-bold mr-2"
                  @click="$refs['confirmBooking'].hide()"
          >
            {{ $t('btn.cancel') }}
          </button>
          <button type="button"
                  class="btn btn-hover-bg-success btn-text-success btn-hover-text-white border-0 font-weight-bold mr-2"
                  :disabled="!form.who"
                  @click="submitForm"
          >
            {{ $t('btn.book') }}
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import i18n from '@/core/plugins/vue-i18n';
import CardLoader from '@/components/elements/loaders/CardLoader';
import CountrySelectFormGroup from '../elements/form-groups/CountrySelectFormGroup';
import DatetimePickerFormGroup from '../elements/form-groups/DatetimePickerFormGroup';
import InputFormGroup from '../elements/form-groups/InputFormGroup';
import MultiselectFormGroup from '../elements/form-groups/MultiselectFormGroup';
import PhoneNumberFormGroup from '../elements/form-groups/PhoneNumberFormGroup';
import RadioFormGroup from '../elements/form-groups/RadioFormGroup';
import TagInputFormGroup from '../elements/form-groups/TagInputFormGroup';
import TextareaFormGroup from '../elements/form-groups/TextareaFormGroup';
import TouchspinFormGroup from '../elements/form-groups/TouchspinFormGroup';
import LocalStorageService from '@/core/services/local-storage.service';

export default {
  components: {
    CardLoader,
    CountrySelectFormGroup,
    DatetimePickerFormGroup,
    InputFormGroup,
    MultiselectFormGroup,
    PhoneNumberFormGroup,
    RadioFormGroup,
    TagInputFormGroup,
    TextareaFormGroup,
    TouchspinFormGroup
  },
  data: () => ({
    isLoading: false,
    bookingOwnerOptions: [
      {id: 'owner', label: i18n.t('label.book_for_myself')},
      {id: 'guest', label: i18n.t('label.book_for_guest')},
    ],
    childrenAgeTags: [],
    childrenTagInputErrorMessage: null,
    disabledDates: [],
    exitDisabledDates: [],
    genders: ['male', 'female'],
    form: {
      apartment_id: null,
      comment: null,
      exit_date: null,
      income_date: null,
      guests: {
        // address: null,
        adults: 1,
        children: 0,
        children_age: [],
        citizen: null,
        // country: null,
        email: null,
        gender: null,
        last_name: null,
        name: null,
        personal_id: null,
        phone_number: null,
        type: null,
      },
      who: null,
    },
    error: {
      apartment_id: false,
      booking_owner: false,
      children_age: false,
      exit_date: false,
      income_date: false,
    },
    modalError: {
      // address: false,
      citizen: false,
      // country: false,
      email: false,
      gender: false,
      last_name: false,
      name: false,
      personal_id: false,
      phone_number: false,
      type: false,
    }
  }),
  async beforeMount() {
    await this.$store.dispatch('apartmentsStore/GET_BILLING_LIST');
    if (this.$route.query?.apartment_id) {
      this.form.apartment_id = parseInt(this.$route.query.apartment_id);
    }
  },
  computed: {
    ...mapGetters({
      apartmentsList: 'apartmentsStore/BILLING_LIST',
      apartmentBookings: 'bookingsStore/FOR_APARTMENT',
    }),
    user() {
      return LocalStorageService.getUser();
    },
    computedApartmentsList() {
      return this.apartmentsList.length ? this.apartmentsList : [];
    },
    computedBookForMyself() {
      return this.form.who === 'owner';
    },
    computedBookForGuest() {
      return this.form.who === 'guest';
    },
    computedChildrenCount() {
      return this.form.guests.children;
    },
    computedApartmentId() {
      return this.form.apartment_id;
    },
    computedGendersList() {
      return this.genders.map(el => {
        return {value: el.toUpperCase(), text: this.$t(`label.${el}`)};
      })
    },
    computedMinIncomeDate() {
      if (!this.apartmentBookings || !this.form.exit_date) return new Date();
      const bookings = [...this.apartmentBookings].reverse();
      let minAllowedDate = bookings.find(el => {
        if (new Date(el.end) < new Date(this.form.exit_date) && new Date(el.end) >= new Date()) {
          return el;
        }
      });

      return minAllowedDate ? new Date(minAllowedDate.end) : new Date();
    },
    computedMaxIncomeDate() {
      let exitDate = new Date(this.form.exit_date);
      return this.form.exit_date ? exitDate.setDate(exitDate.getDate() - 1) : null;
    },
    computedMinExitDate() {
      let incomeDate = new Date(this.form.income_date);
      return this.form.income_date ? incomeDate.setDate(incomeDate.getDate() + 1) : this.computedMinIncomeDate;
    },
    computedMaxExitDate() {
      if (!this.apartmentBookings.length || !this.form.income_date) return null;
      const maxAllowedDate = this.apartmentBookings.find(el => {
        if (new Date(el.start) > new Date(this.form.income_date)) {
          return el;
        }
      });

      return maxAllowedDate ? new Date(maxAllowedDate.start) : null;
    },
    computedGenerateKeyOptions() {
      return [
          {value: null, text: this.$t('label.dont_generate')},
          {value: 'ekey', text: this.$t('label.ekey')},
      ];
    }
  },
  watch: {
    apartmentBookings: {
      handler(data) {
        this.disabledDates = data;
        this.exitDisabledDates = data;
      },
      deep: true
    },
    computedApartmentId(id) {
      this.form.income_date = null;
      this.form.exit_date = null;
      if (id) {
        this.$store.dispatch('bookingsStore/GET_FOR_APARTMENT', id);
      }
    },
    computedBookForMyself(val) {
      if (val) {
        // this.modalError.address = false;
        this.modalError.citizen = false;
        // this.modalError.country = false;
        this.modalError.email = false;
        this.modalError.gender = false;
        this.modalError.last_name = false;
        this.modalError.name = false;
        this.modalError.personal_id = false;
        this.modalError.phone_number = false;
      }
    },
    computedChildrenCount(value) {
      if (!value) {
        this.childrenAgeTags = [];
      }
      this.validateChildrenFormInput();
    },
    computedMaxExitDate(date) {
      this.exitDisabledDates = this.disabledDates.filter(el => el.start === date);
    }
  },
  methods: {
    onTagsInput(e) {
      this.childrenAgeTags = e;
      this.validateChildrenFormInput();
    },
    checkTag(obj) {
      if (!isNaN(obj.tag.text) && obj.tag.text >= 0 && (function (x) {
        return (x | 0) === x;
      })(parseInt(obj.tag.text)) && /^\d+$/.test(obj.tag.text)) {
        obj.addTag();
      } else {
        alert(this.$t('error.invalid_age'))
      }
    },
    clearForm() {
      this.childrenAgeTags = [];
      this.form.apartment_id = null;
      this.form.comment = null;
      this.form.income_date = null;
      this.form.exit_date = null;
      this.form.guests.adults = 1;
      this.form.guests.children = 0;
    },
    showModal() {
      if (!this.validateForm()) return false;
      this.$refs['confirmBooking'].show();
    },
    async submitForm() {
      this.form.guests.children_age = this.childrenAgeTags.map(el => el.text);
      if (!this.validateModalForm()) return false;
      this.isLoading = true;
      this.$store.dispatch('bookingsStore/BOOK', this.form).then(res => {
        this.isLoading = false;
        this.$refs['confirmBooking'].hide();
        this.clearForm();
        this.$emit('apartmentBooked', this.form.who);
      }).catch(err => {
        this.isLoading = false;
      });
    },
    validateForm() {
      this.error.apartment_id = !this.form.apartment_id;
      this.error.income_date = !this.form.income_date;
      this.error.exit_date = !this.form.exit_date;

      return !Object.values(this.error).includes(true);
    },
    validateChildrenFormInput() {
      if (this.form.guests.children !== this.childrenAgeTags.length) {
        this.error.children_age = true;
        this.childrenTagInputErrorMessage = this.$t('error.children_count');
      } else {
        this.error.children_age = false;
      }

      this.childrenAgeTags.map(el => {
        if (parseInt(el.text) > 12) {
          this.error.children_age = true;
          this.childrenTagInputErrorMessage = this.$t('error.children_age');
          return;
        }
      })
    },
    validateModalForm() {
      if (this.computedBookForMyself) return true;

      // this.modalError.address = !this.form.guests.address;
      this.modalError.citizen = !this.form.guests.citizen;
      // this.modalError.country = !this.form.guests.country;
      this.modalError.email = !this.form.guests.email;
      this.modalError.gender = !this.form.guests.gender;
      this.modalError.last_name = !this.form.guests.last_name;
      this.modalError.name = !this.form.guests.name;
      this.modalError.personal_id = !this.form.guests.personal_id;
      this.modalError.phone_number = !this.form.guests.phone_number;

      return !Object.values(this.modalError).includes(true);
    },
    ignoreNonEnglishLetters(e) {
      if(/^[\x00-\xff]+$/.test(e.key)) return true;
      else e.preventDefault();
    },
  }
}
</script>
