<template>
  <div class="form-group">
    <div v-if="label"
         class="form-group__label-wrapper"
         :class="{ required }"
    >
      <label class="form-group__label">{{ label }}</label>
    </div>
    <vue-tel-input
        :value="value"
        :placeholder="placeholder"
        :inputOptions="inputOptions"
        :dropdownOptions="dropdownOptions"
        :defaultCountry="defaultCountry"
        :autoDefaultCountry="false"
        :autoFormat="false"
        @country-changed="countryChanged"
    >
      <template slot="arrow-icon"> <span class="vti__dropdown-arrow">&nbsp;▼</span></template>
    </vue-tel-input>
    <span v-if="helpMessage" class="form-text text-muted">{{ helpMessage }}</span>
    <p v-if="withError" class="error-message text-danger">{{ errorMessage }}</p>
  </div>
</template>

<script>
import { VueTelInput } from 'vue-tel-input';
import i18n from '@/core/plugins/vue-i18n';

export default {
  components: {
    VueTelInput
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    withError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: i18n.t('error.field_is_required')
    },
    helpMessage: {
      type: String,
      default: ''
    },
    autoFormat: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      value: '',
      inputOptions: {
        autocomplete: 'on',
        autofocus: false,
        'aria-describedby': '',
        id: '',
        maxlength: 50,
        name: '',
        showDialCode: false,
        placeholder: this.placeholder,
        readonly: true,
        type: 'text',
      },
      dropdownOptions: {
        disabled: this.disabled,
        showDialCodeInList: false,
        showDialCodeInSelection: false,
        showFlags: true,
        showSearchBox: false,
        tabindex: 0,
        width: 100,
      }
    }
  },
  computed: {
    defaultCountry() {
      // return localStorage.getItem('language');
      return 'ge';
    }
  },
  methods: {
    countryChanged(country) {
      if (country) {
        this.value = country.name;
        this.$emit('select', country.iso2);
      }
    }
  }
}
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>

<style lang="scss">
.form-group {
  position: relative;
}

.form-group__label-wrapper {
  &.required {
    position: relative;
    display: inline-block;

    :after {
      content: "*";
      position: absolute;
      color: #f64e60;
      font-weight: bold;
      right: -10px;
    }
  }
}

.vue-tel-input {
  height: 38px;
  border: 1px solid #E4E6EF;

  & .vti__dropdown {
    position: inherit;

    & .vti__dropdown-list {
      width: 100%;

      &.above {
        margin-bottom: -28px;
      }

      &.below {
        margin-top: 28px;
      }
    }
  }

  & ::placeholder {
    color: #B8B8C5;
  }
}
</style>