<template>
  <b-modal centered hide-header hide-footer id="booking-declined-modal" ref="booking-declined-modal">
    <div class="d-flex flex-column align-items-center">
      <span class="far fa-check h1 text-success mb-5"/>
      <div class="text-center text-success h6 mb-5">
        {{ $t('modal.booking_declined') }}
      </div>
    </div>

    <div class="text-center mb-5">
      {{ $t('modal.booking_declined_body') }}
    </div>

    <div class="d-flex justify-content-center">
      <button type="button"
         class="btn btn-hover-bg-success btn-text-success btn-hover-text-white border-0 font-weight-bold mr-2"
         @click="hideModal"
      >
        {{ $t('btn.ok') }}
      </button>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    bookingId: {
      default: null
    },
    withKey: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    hideModal() {
      this.$refs['booking-declined-modal'].hide()
    },
  }
}
</script>