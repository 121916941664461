<template>
  <div>
    <NewBookingForm v-if="!selectedBookingID" @apartmentBooked="onApartmentBooked"/>
    <BookingDetails
        v-if="selectedBookingID"
        :data="bookingDetails"
        :history-filters="filters"
        @close="selectedBookingID = null"
        @action="updateData"
        @acceptBooking="beforeAcceptBooking"
        @declineBooking="beforeDeclineBooking"
        @generateKey="beforeGenerateKey"
    />
    <div class="row mt-5">
      <div class="col-md-3">
        <Filters
            module-name="bookings"
            :preselectedFilters="preselectedFilters"
            @update="filters = $event"
        />
      </div>
      <div class="col-md-9">
        <BookingList
            :filters="filters"
            :update-data="updateDataFlag"
            :select-item="selectBookingId"
            @statusSelected="onStatusSelected"
            @bookingSelected="onBookingSelected"
        />
      </div>
    </div>

    <AcceptBookingModal
        v-if="bookingDetails"
        :has-key="!!bookingDetails.key"
        :with-key="generateKeyFlag"
        :parallel-bookings="parallelBookings"
        @actionConfirmed="acceptBooking"
    />
    <DeclineBookingModal @actionConfirmed="declineBooking"/>
    <GenerateKeyModal
        v-if="bookingDetails"
        :type="bookingDetails.type"
        @actionConfirmed="generateKey"/>

    <BookingAcceptedModal
        :booking-id="computedBookingID"
        :has-key="!!computedBookingKey"
        :with-key="generateKeyFlag"
    />
    <BookingDeclinedModal/>
    <KeyGeneratedModal/>
    <BookingCreatedModal :owner="newBookingOwner"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import BookingDetails from '@/components/bookings/BookingDetails';
import Filters from '@/components/elements/filters/Filters';
import BookingList from '@/components/bookings/BookingsList';
import NewBookingForm from '@/components/bookings/NewBookingForm';

import AcceptBookingModal from '@/components/elements/modals/AcceptBookingModal';
import BookingAcceptedModal from '@/components/elements/modals/BookingAcceptedModal';
import BookingDeclinedModal from '@/components/elements/modals/BookingDeclinedModal';
import DeclineBookingModal from '@/components/elements/modals/DeclineBookingModal';
import GenerateKeyModal from '@/components/elements/modals/GenerateKeyModal';
import KeyGeneratedModal from '@/components/elements/modals/KeyGeneratedModal';
import BookingCreatedModal from '@/components/elements/modals/BookingCreatedModal';

export default {
  components: {
    BookingDetails,
    Filters,
    BookingList,
    NewBookingForm,

    AcceptBookingModal,
    BookingAcceptedModal,
    BookingDeclinedModal,
    DeclineBookingModal,
    GenerateKeyModal,
    KeyGeneratedModal,
    BookingCreatedModal,
  },
  data: () => ({
    generateKeyFlag: false,
    selectBookingId: null,
    selectedBookingID: null,
    filters: {},
    updateDataFlag: false,
    newBookingOwner: false,
    preselectedFilters: null,
    parallelBookings: []
  }),
  beforeMount() {
    if (this.$route.query.booking_id) {
      const booking = {id: this.$route.query.booking_id};
      this.onBookingSelected(booking);
    }
  },
  computed: {
    ...mapGetters({
      bookingDetails: 'bookingsStore/DETAILS'
    }),
    computedBookingID() {
      return this.bookingDetails?.id;
    },
    computedBookingKey() {
      return this.bookingDetails?.key;
    }
  },
  methods: {
    onBookingSelected(item) {
      this.selectedBookingID = item?.id;
      this.$store.dispatch('bookingsStore/GET_DETAILS', this.selectedBookingID);
    },
    onApartmentBooked(owner) {
      this.newBookingOwner = owner;
      this.$bvModal.show('booking-created-modal');
      this.updateData();
    },
    onStatusSelected(status) {
      this.preselectedFilters = {name: 'status', value: {id: status}, multiple: false};
    },
    beforeAcceptBooking(generateKeyFlag, parallelBookings) {
      this.generateKeyFlag = generateKeyFlag;
      this.parallelBookings = parallelBookings;
      this.$bvModal.show('accept-booking-modal');
    },
    beforeDeclineBooking() {
      this.$bvModal.show('decline-booking-modal');
    },
    beforeGenerateKey() {
      this.$bvModal.show('generate-key-modal');
    },
    async acceptBooking() {
      await this.$store.dispatch('bookingsStore/ACCEPT', {
        bookingID: this.bookingDetails.id,
        dataToSubmit: {
          keySend: this.generateKeyFlag
        }
      });
      this.$bvModal.show('booking-accepted-modal');
      this.updateData(this.bookingDetails.id);
    },
    async declineBooking() {
      await this.$store.dispatch('bookingsStore/DECLINE', this.bookingDetails.id);
      this.$bvModal.show('booking-declined-modal');
      this.updateData(this.bookingDetails.id);
    },
    async generateKey(type) {
      await this.$store.dispatch('bookingsStore/GENERATE_KEY', {
        bookingID: this.bookingDetails.id,
        dataToSubmit: {
          apartmentId: this.bookingDetails.apartment_id,
          startDate: this.bookingDetails.income_date,
          endDate: this.bookingDetails.exit_date,
          reservation_id: this.bookingDetails.id,
          type: type
        }
      });
      this.$bvModal.show('key-generated-modal');
      this.updateData(this.bookingDetails.id);
    },
    updateData(bookingId = null) {
      this.updateDataFlag = true;
      if (bookingId) {
        this.selectBookingId = bookingId;
      }
      setTimeout(() => {
        this.updateDataFlag = false;
        this.selectBookingId = null;
      }, 1000);
    }
  },
  beforeDestroy() {
    this.$store.commit('apartmentsStore/CLEAR_BILLING_LIST');
    this.$store.commit('bookingsStore/CLEAR_DATA');
  },
}
</script>