<template>
  <b-modal centered hide-header hide-footer id="generate-key-modal" ref="generate-key-modal">
    <div class="text-center mb-5">{{ type === 'personal_book' ? $t('modal.generate_key_for_personal_title') : $t('modal.generate_key_for_guest_title') }}</div>
    <div class="text-center mb-5">{{ type === 'personal_book' ? null : $t('modal.generate_key_for_guest_body') }}</div>
    <div class="text-center font-weight-bolder mb-5">{{ $t('modal.generate_key_for_guest_question') }}</div>
    <div class="d-flex justify-content-center">
      <button type="button"
              class="btn btn-hover-bg-danger btn-text-danger btn-hover-text-white border-0 font-weight-bold mr-2"
              @click="hideModal"
      >
        {{ $t('btn.cancel') }}
      </button>
      <button type="button"
              class="btn btn-hover-bg-success btn-text-success btn-hover-text-white border-0 font-weight-bold mr-2"
              @click="submitModal"
      >
        {{ $t('btn.send') }}
      </button>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    type: {
      default: null
    },
  },
  methods: {
    submitModal() {
      this.hideModal();
      this.$emit('actionConfirmed');
    },
    hideModal() {
      this.$refs['generate-key-modal'].hide()
    },
  }
}
</script>