<template>
  <div class="form-group">
    <div v-if="withLabel"
         class="form-group__label-wrapper"
         :class="{ required }"
    >
      <label class="form-group__label">{{ label }}</label>
    </div>
    <vue-tags-input
        class="app-input form-control"
        :class="{error: withError, disabled: disabled}"
        v-model="tag"
        :placeholder="placeholder"
        :tags="value"
        :disabled="disabled"
        :add-on-key="[13, ' ', ',']"
        :avoid-adding-duplicates="false"
        :is-duplicate="isDuplicateTag"
        @before-adding-tag="beforeAddingTag"
        @tags-changed="$emit('input', $event)"
    />
    <p v-if="withError" class="error-message">{{ errorMessage }}</p>
  </div>
</template>

<script>
import i18n from '@/core/plugins/vue-i18n';
import VueTagsInput from '@johmun/vue-tags-input';

export default {
  components: {
    VueTagsInput
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      default: () => {
        return [];
      }
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    withLabel: {
      type: Boolean,
      default: true
    },
    withError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: i18n.t("error.field_is_required")
    },
    beforeAddingTag: {
      type: Function,
      default: () => {}
    }
  },

  data: () => ({
    tag: ''
  }),
  methods: {
    isDuplicateTag() {
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;
}

.form-group__label-wrapper {
  &.required {
    position: relative;
    display: inline-block;

    :after {
      content: "*";
      position: absolute;
      color: #f64e60;
      font-weight: bold;
      right: -10px;
    }
  }
}

.form-control::v-deep {
  height: auto !important;
  min-height: 38px;
  max-width: initial;

  .vue-tags-input ::-webkit-input-placeholder {
    color: #dcdcdc !important;
  }

  & .ti-input {
    margin: -4px 0 0 -5px;
    padding: 0;
    min-height: 26px;
    border: 0;

    & .ti-new-tag-input-wrapper {
      font-weight: 400;
      line-height: 1.5;
      color: #464E5F;
      font-size: 1rem;

      & .ti-new-tag-input {
        color: #464E5F;
      }
    }
  }
}

.error-message {
  position: absolute;
  bottom: -32px;
  left: 0;
  font-size: 0.9rem;
  color: #f64e60;
}

.app-input {
  height: 38px;
}

.app-input.error {
  border-color: #f64e60;
}
</style>
