<template>
  <div class="card card-custom h-100">
    <div class="card-header align-items-center">
      <div class="card-title">
        <a class="btn btn-sm btn-text-primary font-weight-bold"
           :class="mode === 'table' ? 'btn-light-primary' : 'btn-hover-light-primary'"
           @click="mode = 'table'"
        >
          {{ $t('label.table') }}
        </a>
        <span class="mx-1">/</span>
        <a class="btn btn-sm btn-text-primary font-weight-bold"
           :class="mode === 'calendar' ? 'btn-light-primary' : 'btn-hover-light-primary'"
           @click="mode = 'calendar'"
        >
          {{ $t('label.calendar') }}
        </a>
      </div>
      <div>
        <span
            class="label label-inline label-rounded cursor-pointer py-4"
            :class="this.selectedStatus === 'approved' ? 'label-success' : 'label-light-success'"
            @click="filterByStatus('approved')"
        >
          {{ $t('status.booking.approved') }}
        </span>
        <span class="px-1">|</span>
        <span
            class="label label-inline label-rounded cursor-pointer py-4"
            :class="this.selectedStatus === 'new' ? 'label-warning' : 'label-light-warning'"
            @click="filterByStatus('new')"
        >
          {{ $t('status.booking.new') }}
          <span v-if="newBookingsCount" class="label label-sm label-danger ml-1">{{ newBookingsCount }}</span>
        </span>
        <span class="px-1">|</span>
        <b-badge
            class="label label-inline label-rounded cursor-pointer py-4"
            :class="this.selectedStatus === 'canceled' ? 'label-danger' : 'label-light-danger'"
            @click="filterByStatus('canceled')"
        >
          {{ $t('status.booking.canceled') }}
        </b-badge>
      </div>
      <div class="card-toolbar">
        <button type="button" class="btn btn-light-success" @click="exportBookingHistory">
          <i class="far fa-file-spreadsheet"></i> {{ $t('btn.export_history') }}
        </button>
      </div>
    </div>
    <div class="card-body p-0">
      <BootstrapVueTable
          v-if="mode === 'table'"
          request-url="api/bookings/table"
          module-name="bookings"
          :filters="filters"
          with-pagination
          selectable
          :update-data="updateData"
          @itemSelected="onItemSelected"
      />
      <VueCalScheduler
          v-if="mode === 'calendar'"
          class="h-100"
          :events="events"
          :selected-date="startDate"
          @getData="getData"
          @itemSelected="onItemSelected"
      />
    </div>
  </div>
</template>

<script>
import BootstrapVueTable from '@/components/elements/tables/BootstrapVueTable';
import VueCalScheduler from '@/components/elements/calendars/VueCalScheduler';
import {mapGetters} from "vuex";

export default {
  components: {
    BootstrapVueTable,
    VueCalScheduler
  },
  props: {
    filters: {
      default: null
    },
    updateData: {
      type: Boolean,
      default: false
    },
    selectItem: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    mode: 'table',     // calendar / table
    dateRange: null,
    startDate: null,
    selectedStatus: null
  }),
  beforeMount() {
    this.startDate = this.$route.query.start_date;
  },
  computed: {
    ...mapGetters({
      events: 'bookingsStore/EVENTS',
      newBookingsCount: 'notificationsStore/NEW_BOOKINGS_COUNT',
    })
  },
  watch: {
    filters() {
      this.getData(null);
    },
    updateData(val) {
      if (val && this.mode === 'calendar') {
        let today = new Date();
        this.startDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        this.getData(null);
      }
    },
    selectItem(id) {
      if (id) {
        let item = this.events.find(el => el.id === id);
        this.onItemSelected(item);
      }
    }
  },
  methods: {
    filterByStatus(status) {
      if (this.selectedStatus === status) {
        status = null;
      }
      this.$emit('statusSelected', status);
      this.selectedStatus = status;
    },
    async getData(e) {
      await this.$store.dispatch('bookingsStore/GET_EVENTS', {
        filters: this.filters,
        date_range: e
      })
    },
    onItemSelected(item) {
      this.$emit('bookingSelected', item);
    },
    exportBookingHistory() {
      this.$store.dispatch('bookingsStore/EXPORT_HISTORY', this.filters);
    }
  }
}
</script>