<template>
  <b-modal centered hide-header hide-footer id="booking-accepted-modal" ref="booking-accepted-modal">
    <div class="d-flex flex-column align-items-center">
      <span class="far fa-check h1 text-success mb-5"/>
      <div class="text-center text-success h6 mb-5">
        {{ `${$t('label.booking_accepted')}` }}
      </div>
    </div>

    <div class="text-center mb-5">{{ $t('modal.booking_accepted_body') }}</div>

    <div v-if="hasKey">
      <div v-if="withKey" class="mb-5">
        <div class="text-center mb-5">{{ $t('modal.key_generated') }}</div>
        <div class="text-center">{{ $t('modal.booking_accepted') }}</div>
      </div>
      <div v-else class="text-center mb-5">
        {{ $t('modal.you_can_generate_key') }}
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <button type="button"
              class="btn btn-hover-bg-success btn-text-success btn-hover-text-white border-0 font-weight-bold mr-2"
              @click="hideModal"
      >
        {{ $t('btn.ok') }}
      </button>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    bookingId: {
      default: null
    },
    hasKey: {
      type: Boolean,
      default: false
    },
    withKey: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    hideModal() {
      this.$refs['booking-accepted-modal'].hide()
    },
  }
}
</script>