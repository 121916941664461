<template>
  <div class="card card-custom">
    <div class="card-header">
      <div class="card-title">
        {{ $t('title.filters') }}
      </div>
      <div class="card-toolbar">
        <button
            type="button"
            class="btn btn-sm btn-text-danger btn-hover-light-danger font-weight-bold"
            @click="onReject"
        >
          {{ $t('btn.reject') }}
        </button>
      </div>
    </div>
    <div class="card-body">
      <div v-for="filter in filtersConfig" :key="filter.code">
        <InputFormGroup v-if="filter.filter_type === 'input'"
            :label="filter.title"
            :value="filters[filter.code]"
            @input="debouncedUpdate(filter.code, $event)"
        />
        <MultiselectFormGroup v-if="filter.filter_type === 'select'"
            class="app-view__form-group"
            :label="filter.title"
            :value="getSelectValue(filter)"
            :options="filter.options || []"
            :multiple="true"
            @select="onSelect(filter.code, $event, true)"
            @remove="filters[filter.code] = filters[filter.code].filter((el) => el !== $event.id)"
        />
        <MultiselectFormGroup v-if="filter.filter_type === 'boolean'"
            class="app-view__form-group"
            :label="filter.title"
            :value="getSelectValue(filter)"
            :options="filter.options"
            @select="onSelect(filter.code, $event)"
            @remove="$delete(filters, filter.code)"
        />
        <div v-if="filter.filter_type === 'number'"
             class="form-group"
        >
          <label class="form-group__label">{{ filter.title }}</label>
          <div class="d-flex flex-row">
            <InputFormGroup
                class="mr-1"
                :placeholder="$t('placeholder.from')"
                :value="getRangeValue(filter.code, 'from')"
                @input="debouncedUpdate(filter.code, {...filters[filter.code], from: $event})"
            />
            <InputFormGroup
                :placeholder="$t('placeholder.to')"
                :value="getRangeValue(filter.code, 'to')"
                @input="debouncedUpdate(filter.code, {...filters[filter.code], to: $event})"
            />
          </div>
        </div>
        <div v-if="filter.filter_type === 'date'"
             class="form-group"
        >
          <label class="form-group__label">{{ filter.title }}</label>
          <div class="d-flex flex-row">
<!--            :model-config="{type: 'string', mask: 'DD.MM.YYYY'}"-->
            <DatetimePickerFormGroup
                class="mr-1"
                :value="getRangeValue(filter.code, 'from')"
                :placeholder="$t('placeholder.from')"
                :with-icon="false"
                :max-datetime="filters[filter.code] ? filters[filter.code].to : null"
                allow-clear
                @change="debouncedUpdate(filter.code, {...filters[filter.code], from: $event})"
            />
            <DatetimePickerFormGroup
                :value="getRangeValue(filter.code, 'to')"
                :placeholder="$t('placeholder.to')"
                :with-icon="false"
                :min-datetime="filters[filter.code] ? filters[filter.code].from : null"
                allow-clear
                @change="debouncedUpdate(filter.code, {...filters[filter.code], to: $event})"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import DatetimePickerFormGroup from '@/components/elements/form-groups/DatetimePickerFormGroup';
import InputFormGroup from '@/components/elements/form-groups/InputFormGroup';
import MultiselectFormGroup from '@/components/elements/form-groups/MultiselectFormGroup';

export default {
  components: {
    DatetimePickerFormGroup,
    InputFormGroup,
    MultiselectFormGroup
  },
  props: {
    moduleName: {
      type: String,
      default: null
    },
    preselectedFilters: {
      default: null
    }
  },
  async created() {
    const DEBOUNCE_TIME = 400;
    this.debouncedUpdate = _.debounce(this.debouncedUpdate, DEBOUNCE_TIME);
  },
  data: () => ({
    filtersConfig: {},
    filters: {},
    filtersLoaded: false
  }),
  watch: {
    preselectedFilters: {
      handler(data) {
        this.onSelect(data.name, data.value, data.multiple);
      },
      deep: true
    },
    filters: {
      handler(filters) {
        let result = {};

        for (let key in filters) {
          if (filters.hasOwnProperty(key) && filters[key]) {
            if (Array.isArray(filters[key]) && !filters[key].length) continue;
            if (!Array.isArray(filters[key]) && typeof filters[key] === 'object' && !filters[key].from && !filters[key].to) continue;
            result[key] = filters[key];
          }
        }

        this.$emit('update', result);
      },
      deep: true
    },
    moduleName: {
      immediate: true,
      async handler(val) {
        if (val) {
          this.filtersConfig = await this.$store.dispatch('tableStore/GET_FILTERS', val);
          setTimeout(() => {
            this.filtersLoaded = true;
          }, 500);
        }
      }
    }
  },
  methods: {
    debouncedUpdate(key, value) {
      if (!this.filtersLoaded) return false;
      this.$set(this.filters, key, value);
    },
    getSelectValue(item) {
      if (!this.filters[item.code]) return null;
      return item.options.filter((op) => this.filters[item.code].find((el) => el === op.id));
    },
    getRangeValue(key, range) {
      return this.filters[key] ? this.filters[key][range] : null;
    },
    onSelect(key, e, multiple = false) {
      if (!e) {
        this.$delete(this.filters, key);
        return;
      }
      if (multiple) {
        this.$set(this.filters, key, this.filters[key] ? [...this.filters[key], e.id] : [e.id]);
      } else {
        this.$set(this.filters, key, [e.id]);
      }
    },
    onReject() {
      this.filters = {};
    }
  }
}
</script>

<style lang="scss" scoped>
.form-group::v-deep {
  position: relative;

  & .form-group__label {
    position: absolute;
    top: -10px;
    left: 7px;
    background: #fff;
    z-index: 2;
  }
}
</style>