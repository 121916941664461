<template>
  <div>
    <div v-if="!data" class="card card-custom bg-light skeleton-box w-100">
      <div class="card-body h-100px"></div>
    </div>
    <div v-else class="card card-custom w-100">
      <div class="card-header">
        <div class="card-title">
          {{ $t('title.booking_details') }}
          <span v-if="data.key"
                class="fas fa-key font-size-h4 ml-3"
                :class="data.key === 'generated' ? 'text-success' : 'text-danger'"
          />
        </div>
        <div class="card-toolbar">
          <button v-if="data.key === 'not_generated' && data.status === 'approved'"
                  type="button"
                  class="btn btn-light-info"
                  @click="$emit('generateKey')"
          >
            <i class="fas fa-key"></i> {{ $t('btn.generate_key') }}
          </button>
          <div v-if="data.key && data.status === 'new'" class="d-flex align-items-center mr-2">
            <div class="mr-2">
            <span class="switch switch-sm switch-outline switch-brand">
              <label>
                 <input type="checkbox" v-model="generateNewKeyFlag" checked="checked" name="select"/>
                 <span></span>
              </label>
           </span>
            </div>
            <label class="col-form-label">{{ $t('btn.key_generation') }}</label>
          </div>
<!--          <button v-if="!oldBooking && data.status !== 'canceled'"-->
<!--                  type="button"-->
<!--                  class="btn btn-light-warning ml-2"-->
<!--                  @click="goToServicesList"-->
<!--          >-->
<!--            <i class="far fa-shopping-basket"></i> {{ $t('btn.order_service') }}-->
<!--          </button>-->
<!--          <button type="button" class="btn btn-light-success ml-2" @click="goToOrdersList">-->
<!--            <i class="far fa-shopping-basket"></i> {{ $t('btn.show_orders') }}-->
<!--          </button>-->
          <button type="button" class="btn btn-light-primary ml-2" @click="goToNewTicket">
            <i class="far fa-comment"></i> {{ $t('btn.support') }}
          </button>
          <button
              v-if="futureBooking && data.status === 'approved'"
              type="button"
              class="btn btn-light-danger ml-2"
              @click="$emit('declineBooking')"
          >
            <i class="far fa-times"></i> {{ $t('btn.cancel') }}
          </button>
          <span class="far fa-times text-muted h4 cursor-pointer p-3 mb-0 ml-3" @click="$emit('close')"/>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-4">
            <div class="form-group row my-0">
              <label class="col-5 col-form-label">{{ $t('label.id') }}:</label>
              <div class="col-7">
                <span class="form-control-plaintext font-weight-bolder">{{ data.id }}</span>
              </div>
            </div>
            <div class="form-group row my-0">
              <label class="col-5 col-form-label">{{ $t('label.object') }}:</label>
              <div class="col-7">
                <span class="form-control-plaintext font-weight-bolder">{{ data.object }}</span>
              </div>
            </div>
            <div class="form-group row my-0">
              <label class="col-5 col-form-label">{{ $t('label.apartment') }}:</label>
              <div class="col-7">
                <span class="form-control-plaintext font-weight-bolder">{{ data.apartment }}</span>
              </div>
            </div>
            <div class="form-group row my-0">
              <label class="col-5 col-form-label">{{ $t('label.nights_count') }}:</label>
              <div class="col-7">
                <span class="form-control-plaintext font-weight-bolder">{{ data.nights_count }}</span>
              </div>
            </div>
            <div class="form-group row my-0 d-flex align-items-center">
              <label class="col-5 col-form-label">{{ $t('label.status') }}:</label>
              <div class="col-7">
                  <span v-if="data.status === 'canceled'"
                        class="form-control-plaintext label label-danger label-pill label-inline"
                  >
                    {{ $t(`status.booking.${data.status}`) }}
                  </span>
                <span v-else-if="data.status === 'new'"
                      class="form-control-plaintext label label-warning text-dark label-pill label-inline"
                >
                  {{ $t(`status.booking.${data.status}`) }}
                </span>
                <span v-else-if="data.status === 'approved'"
                      class="form-control-plaintext label label-success label-pill label-inline"
                >
                  {{ $t(`status.booking.${data.status}`) }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group row my-0">
              <label class="col-5 col-form-label">{{ $t('label.full_name') }}:</label>
              <div class="col-7">
                <span class="form-control-plaintext font-weight-bolder">{{ data.full_name || '-' }}</span>
              </div>
            </div>
            <div class="form-group row my-0">
              <label class="col-5 col-form-label">{{ $t('label.email') }}:</label>
              <div class="col-7">
                <span class="form-control-plaintext font-weight-bolder">{{ data.email || '-' }}</span>
              </div>
            </div>
            <div class="form-group row my-0">
              <label class="col-5 col-form-label">{{ $t('label.phone_number') }}:</label>
              <div class="col-7">
                <span class="form-control-plaintext font-weight-bolder">{{ data.phone_number || '-' }}</span>
              </div>
            </div>
            <div class="form-group row my-0">
              <label class="col-5 col-form-label">{{ $t('label.income_date') }}:</label>
              <div class="col-7">
                <span class="form-control-plaintext font-weight-bolder">{{ data.income_date }}</span>
              </div>
            </div>
            <div class="form-group row my-0">
              <label class="col-5 col-form-label">{{ $t('label.exit_date') }}:</label>
              <div class="col-7">
                <span class="form-control-plaintext font-weight-bolder">{{ data.exit_date }}</span>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group row my-0">
              <label class="col-5 col-form-label">{{ $t('label.adults') }}:</label>
              <div class="col-7">
                <span class="form-control-plaintext font-weight-bolder">{{ data.adults || '-' }}</span>
              </div>
            </div>
            <div class="form-group row my-0">
              <label class="col-5 col-form-label">{{ $t('label.children') }}:</label>
              <div class="col-7">
                <span class="form-control-plaintext font-weight-bolder">{{ data.children || '-' }}</span>
              </div>
            </div>
            <div class="form-group row my-0">
              <label class="col-5 col-form-label">{{ $t('label.comment') }}:</label>
              <div class="col-7">
                <span class="form-control-plaintext font-weight-bolder">{{ data.comment || '-' }}</span>
              </div>
            </div>
<!--            <div class="form-group row my-0">-->
<!--              <label class="col-5 col-form-label">{{ $t('label.orders_total') }}:</label>-->
<!--              <div class="col-7">-->
<!--                <span class="form-control-plaintext font-weight-bolder">-->
<!--                  {{ data.orders.totalSum ? `${data.orders.totalSum} ${data.orders.currency}` : 0 }}</span>-->
<!--              </div>-->
<!--            </div>-->

            <div v-if="data.status === 'new'"
                 class="d-flex flex-column align-items-center booking-details__btn-wrapper bg-light py-2">
              <span class="font-size-lg mb-1">{{ $t('label.confirm_booking') }}</span>
              <div>
                <button type="button"
                        class="btn btn-danger mr-3"
                        @click="$emit('declineBooking')"
                >
                  {{ $t('btn.decline') }}
                </button>
                <button type="button" class="btn btn-success" @click="onAcceptNewBooking">
                  {{ $t('btn.accept') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    historyFilters: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    generateNewKeyFlag: false,
  }),
  computed: {
    oldBooking() {
      return this.data && new Date(this.data.exit_original_date) < new Date();
    },
    futureBooking() {
      return this.data && new Date(this.data.income_original_date) > new Date();
    },
  },
  watch: {
    data() {
      this.generateNewKeyFlag = false;
    }
  },
  methods: {
    async onAcceptNewBooking() {
      const { data } = await this.$store.dispatch('bookingsStore/CONFIRM_PREDICT', this.data.id);
      this.$emit('acceptBooking', this.generateNewKeyFlag, data.data);
    },
    goToNewTicket() {
      // this.$router.push({name: 'support', query: {new: true, title: `${this.$t('label.booking')} #${this.data.id}`}});
      this.$router.push({name: 'support', query: {new: true}});
    },
    goToServicesList() {
      this.$router.push({name: 'servicesList', query: {booking_id: this.data.id}});
    },
    goToOrdersList() {
      this.$router.push({name: 'bookingOrders', params: {bookingId: this.data.id}});
    }
  }
}
</script>

<style lang="scss">
.skeleton-box {
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: #DDDBDD;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
            90deg,
            rgba(#fff, 0) 0,
            rgba(#fff, 0.2) 20%,
            rgba(#fff, 0.5) 60%,
            rgba(#fff, 0)
    );
    animation: shimmer 3s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
</style>