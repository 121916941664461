<template>
  <b-modal centered hide-header hide-footer id="accept-booking-modal" ref="accept-booking-modal">
    <div v-if="hasKey">
      <div v-if="withKey">
        <div class="text-center mb-5">{{ $t('modal.accept_booking_with_key_title') }}</div>
        <div class="text-center mb-5">{{ $t('modal.generate_key_for_guest_body') }}</div>
        <div v-if="parallelBookings.length" class="text-center mb-5">
          {{ $t('modal.accept_booking_parallel_bookings') }} {{ parallelBookingIds }}
        </div>
        <div class="text-center font-weight-bolder mb-5">{{ $t('modal.accept_booking_with_key_question') }}</div>
      </div>
      <div v-else>
        <div class="text-center mb-5">{{ $t('modal.accept_booking_without_key_title') }}</div>
        <div v-if="parallelBookings.length" class="text-center mb-5">
          {{ $t('modal.accept_booking_parallel_bookings') }} {{ parallelBookingIds }}
        </div>
        <div class="text-center font-weight-bolder mb-5">{{ $t('modal.accept_booking_question') }}</div>
      </div>
    </div>
    <div v-else>
      <div v-if="parallelBookings.length" class="text-center mb-5">
        {{ $t('modal.accept_booking_parallel_bookings') }} {{ parallelBookingIds }}
      </div>
      <div class="text-center font-weight-bolder mb-5">{{ $t('modal.accept_booking_question') }}</div>
    </div>

    <div class="d-flex justify-content-center">
      <button
        type="button"
        class="btn btn-hover-bg-danger btn-text-danger btn-hover-text-white border-0 font-weight-bold mr-2"
        @click="hideModal"
      >
        {{ $t('btn.cancel') }}
      </button>
      <button
        type="button"
        class="btn btn-hover-bg-success btn-text-success btn-hover-text-white border-0 font-weight-bold mr-2"
        @click="submitModal"
      >
        {{ $t('btn.accept') }}
      </button>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    hasKey: {
      type: Boolean,
      default: false
    },
    withKey: {
      type: Boolean,
      default: false
    },
    parallelBookings: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    parallelBookingIds() {
      return this.parallelBookings.map(el => el.id);
    }
  },
  methods: {
    submitModal() {
      this.hideModal();
      this.$emit('actionConfirmed');
    },
    hideModal() {
      this.$refs['accept-booking-modal'].hide()
    },
  }
}
</script>