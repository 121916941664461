<template>
  <div class="app-table__wrapper table-responsive">
    <CardLoader v-if="isLoading"/>
    <b-table
        :items="items"
        :fields="fields"
        :current-page="page"
        :per-page="0"
        :select-mode="selectMode"
        show-empty
        no-border-collapse
        sticky-header="475px"
        responsive
        :selectable="selectable"
        table-class="text-nowrap"
        :empty-text="$t('table.empty')"
        :foot-clone="withFooter"
        @row-selected="onRowSelected"
    >
      <template #head()="scope">
        <div class="text-nowrap"
             :class="{'text-right': isNumber(scope) || isMoney(scope),'text-center': isButton(scope)}"
        >
          {{ scope.field.label }}
        </div>
      </template>

      <template #cell()="row">
        <div class="cursor-pointer"
             :class="{'text-right': isNumber(row) || isMoney(row), 'text-center': isButton(row)}"
        >

          <span v-if="isLink(row)">
            <router-link
                v-if="row.value"
                :to="row.value.url"
                v-slot="{ href, navigate }"
            >
              <a :href="href" class="m-link font-weight-bolder" @click="navigate">
                <span class="menu-text">{{ row.value.text }}</span>
              </a>
            </router-link>
            <span v-else>-</span>
          </span>
          <span v-else-if="isMoney(row)">{{ `${row.value.value} ${row.value.currency_symbol}` }}</span>
          <span v-else-if="isButton(row)">
            <router-link
                v-if="row.value"
                :to="row.value.url"
                v-slot="{ href, navigate }"
            >
              <button type="button" class="btn btn-sm btn-primary" @click="navigate">{{ row.value.text }}</button>
            </router-link>
            <span v-else>-</span>
          </span>
          <span v-else-if="isFile(row)">
            <div v-for="file in row.value">
              <span class="fad fa-file-invoice-dollar h4 text-info mr-2"></span>
              <span class="text-primary text-hover-info" @click="$emit('fileClicked', file)">{{ file.file_name }}</span>
            </div>
          </span>
          <span v-else class="text-wrap">
            {{ row.value || '-' }}
          </span>
        </div>
      </template>

      <template #cell(status)="row">
        <div class="text-nowrap">
          <span v-if="moduleName === 'payments'"
                class="label label-pill label-inline"
                :class="`label-${getPaymentStatusColor(row)}`"
          >
            {{ row.value }}
          </span>
          <span v-if="moduleName === 'bookings'"
                class="label label-pill label-inline"
                :class="`label-${getBookingStatusColor(row)}`"
          >
            {{ $t(`status.booking.${row.value}`) }}
          </span>
        </div>
      </template>

      <!--   ------------------------------------------bookings start------------------------------------------   -->
      <template v-if="moduleName === 'bookings'" #cell(key)="row">
        <div class="text-nowrap">
          <span v-if="row.value"
                class="fas fa-key"
                :class="row.value === 'generated' ? 'text-success' : 'text-danger'"
          />
        </div>
      </template>
      <!--   -------------------------------------------bookings end-------------------------------------------   -->

      <template v-if="withFooter && total" #foot()="data">
        <div class="text-nowrap"
             :class="{'text-right': isNumber(data) || isMoney(data),'text-center': isButton(data)}"
        >
          <span v-if="isMoney(data)">{{ `${total[data.column].value} ${total[data.column].currency_symbol}` }}</span>
          <span v-else>{{ total[data.column] || '-' }}</span>
        </div>
      </template>
    </b-table>

    <div class="d-flex justify-content-between px-3">
      <b-col sm="7" md="6" class="my-1 px-0">
        <b-pagination
            v-if="withPagination"
            v-model="page"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            class="mb-5"
            @input="page = $event"
        ></b-pagination>
      </b-col>
      <div class="my-1 d-flex flex-row justify-content-center align-items-center">
        <span class="mr-2">{{ $t('table.per_page') }}</span>
        <b-form-select
            id="per-page-select"
            class="w-60px pr-5"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
            @change="perPage = $event"
        ></b-form-select>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import CardLoader from '@/components/elements/loaders/CardLoader';

export default {
  components: {
    CardLoader
  },
  props: {
    filters: {
      default: null
    },
    moduleName: {
      type: String,
      default: '',
    },
    requestUrl: {
      type: String,
      default: '',
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    selectMode: {
      type: String,
      default: 'single'
    },
    withPagination: {
      type: Boolean,
      default: false
    },
    withFooter: {
      type: Boolean,
      default: false
    },
    updateData: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      items: [],
      fields: [],
      showFilters: true,
      page: 1,
      perPage: 10,
      pageOptions: [10, 50, 100],   // {value: 100, text: "Show a lot"}
      tableData: null,
      total: null,
      isLoading: false,
    }
  },
  async beforeMount() {
    this.fields = await this.$store.dispatch('tableStore/GET_COLUMNS', this.moduleName);
    await this.getData();

  },
  computed: {
    ...mapGetters({
      totalRows: 'tableStore/TOTAL_ROWS'
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return {text: f.label, value: f.key}
          })
    }
  },
  watch: {
    tableData(data) {
      this.isLoading = false;
      if (!data) return;
      this.items = data.data;
      this.total = data.meta?.total || 0;
    },
    page() {
      this.getData();
    },
    perPage() {
      this.getData();
    },
    filters: {
      handler() {
        this.getData();
      },
      deep: true
    },
    updateData(value) {
      if (value) {
        this.getData();
      }
    }
  },
  methods: {
    toggleFilters() {
      this.showFilters = !this.showFilters;
    },
    onRowSelected(data) {
      let item = data.length ? data[0] : null;
      this.$emit('itemSelected', item);
    },
    onPageChange(page) {
      this.page = page;
      this.getData();
    },
    async getData() {
      this.isLoading = true;
      this.tableData = await this.$store.dispatch('tableStore/GET_ITEMS', {
        requestUrl: this.requestUrl,
        filters: this.filters,
        pagination: {
          page: this.page,
          per_page: this.perPage
        }
      });
    },
    isButton(row) {
      return row.field.column_type === 'button';
    },
    isFile(row) {
      return row.field.column_type === 'file';
    },
    isLabel(row) {
      return row.field.column_type === 'label';
    },
    isLink(row) {
      return row.field.column_type === 'link';
    },
    isMoney(row) {
      return row.field.column_type === 'money';
    },
    isNumber(row) {
      return row.field.column_type === 'number';
    },

    getBookingStatusColor(row) {
      let color;
      switch (row.value) {
        case 'canceled':
          color = 'danger';
          break;
        case 'approved':
          color = 'success';
          break;
        case 'new':
          color = 'warning text-dark';
          break;
      }
      return color;
    },
    getPaymentStatusColor(row) {
      let color;
      switch (row.item.status_code) {
        case 'expired':
        case 'rejected':
          color = 'danger';
          break;
        case 'not_paid':
          color = 'dark';
          break;
        case 'paid':
          color = 'success';
          break;
        case 'partially':
          color = 'warning text-dark';
          break;
      }
      return color;
    }
  }
}
</script>