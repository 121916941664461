<template>
  <b-modal centered hide-header hide-footer id="decline-booking-modal" ref="decline-booking-modal">
    <div class="text-center font-weight-bolder mb-5">{{ $t('modal.decline_booking_question') }}</div>

    <div class="d-flex justify-content-center">
      <button type="button"
              class="btn btn-clean btn-hover-text-dark border-0 font-weight-bold mr-2"
              @click="hideModal"
      >
        {{ $t('btn.no') }}
      </button>
      <button type="button"
              class="btn btn-hover-bg-danger btn-text-danger btn-hover-text-white border-0 font-weight-bold mr-2"
              @click="submitModal"
      >
        {{ $t('btn.yes') }}
      </button>
    </div>
  </b-modal>
</template>

<script>
export default {
  methods: {
    submitModal() {
      this.hideModal();
      this.$emit('actionConfirmed');
    },
    hideModal() {
      this.$refs['decline-booking-modal'].hide()
    },
  }
}
</script>